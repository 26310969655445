<template>
	<div>
		<div style="padding: 5%;font-size: 12px;">
			<div style="font-size: 16px;">
				您正在咨询 【{{zjinfo?zjinfo.zj_name:''}}】 {{zjinfo?zjinfo.title_name:''}}
			</div>
			<div v-if="zjinfo&&zjinfo.zj_headimg" style="line-height: 30px; padding: 10px 0;">
				<img :src="zjinfo.zj_headimg||zjinfo.headimgurl"
					style="width: 30px; height: 30px; border-radius: 50%; vertical-align: middle;">
				<div style="display: inline-block; vertical-align: middle; margin-left: 10px;">
					请在下方输入您要咨询的内容
				</div>
				<div></div>
			</div>
			
			<zxeditor :tc="tcinfo" v-if="tcinfo" :opt="{btntxt:'提交咨询'}"></zxeditor>
		</div>


		
		
	</div>
</template>

<script>
	let timer = 0;
	import zxeditor from "./com/zxeditor.vue"
	export default {
		components:{zxeditor},
		data() {
			return {
				ImageList: [],
				msgcontent: "",
				taped: false,
				zjid: 0,
				tcid: 0,
				orderid:0,
				tcinfo: null,
				zjinfo: null,
				lastDom: null,
				process:0
				

			}
		},
		created() {


		},
		mounted() {
			this.zjid = this.$route.params.id
			this.getMyZixun()
			

		},
		methods: {
			
			

			getMyZixun() {
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=icanzx_this_zj&zj_id=" + this.zjid
				}).then(res => {
					if (res.data) {
						this.tcid = res.data[0].tc_id
						this.orderid =  res.data[0].id
						this.getTCInfo()

					} else {
						$Toast("请先购买咨询套餐")
						setTimeout(()=>{
							this.$router.push("/zxbuy")
						},3000)
					}
				})
			},
			getTCInfo() {
				this.$store.dispatch("POST", {
					url: "/api/zixun/?action=taocan_one",
					query: {
						tcid: this.tcid
					}
				}).then(res => {
					if (res.data) {
						if(res.data.ctype){
							res.data.ctype = JSON.parse(res.data.ctype)
						}
						this.tcinfo = res.data
						this.getZjInfo()
					} else {
						$Toast("数据错误，请联系客服！")
					}
				})
			},
			getZjInfo() {
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zjone&id=" + this.zjid
				}).then(res => {
					this.zjinfo = res.data.rows[0]
				})
			},
			

			submiteWrite(e) {
				
				let conent =e
				if (!conent) {
					return
				}
				if (this.taped) {
					return
				} else {
					setTimeout(() => {
						this.taped = false
					}, 2000)
				}
				
				$confirm({
					msg: "确认提交?",
					okcb: _ => {
						this.taped = true;
						$("#loading").show();
						this.$store.dispatch("POSTJSON", {
							url: "/api/zixun/?action=zxsave",
							para: {
								zj_id: this.$route.params.id,
								orderid:this.orderid,
								msgcontent: (conent)
							}
						}).then(result => {
							let data = result.data


							if (data.code == 200) {
								setTimeout(() => {
									$alert({
										msg: "提交成功，请耐心等待专家回复！",
										okcb: () => {
											this.$router.push("/zxmy")
										}
									})
								}, 300)

							} else {
								setTimeout(() => {
									$alert({
										msg: data.msg
									})
								}, 300)

							}

							$("#loading").hide()
						})
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.qstinput {
		width: 96%;

		padding: 2%;
		font-size: 14px;
		margin: 0;
		border: 1px solid #f6f6f6;
		background: rgba(255, 255, 255, 0.7);
		height: 120px;
	}

	.imagebox {
		width: 29vw;
		height: 29vw;
		margin-right: 1.5vw;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 1.5vw;
		background-position: center;
		background-size: 100%;
		background-repeat: no-repeat;
		border: 1px solid #f6f6f6;
		box-sizing: border-box;
		position: relative;
	}

	.imagebox:nth-child(3n) {
		margin-right: 0;
	}

	.remove {
		position: absolute;
		width: 20px;
		height: 20px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/8586239857155945387.png);
		background-size: cover;
		right: -4px;
		top: -4px;
	}


</style>
